import { Atom, SHARED_VALIDATIONS } from ":mods";

export function createExhibitionIdeaInputs() {
  const exhibition_title = Atom.Form.createInput("", {
    required: true,
  });
  const exhibition_about = Atom.Form.createTextArea("", {
    required: true,
  });
  const exhibition_interest = Atom.Form.createTextArea("", {
    required: true,
  });
  const exhibition_experience = Atom.Form.createTextArea("", {
    required: true,
  });
  const ExhibitionPdf = Atom.Form.createInputFile(null, {
    required: false,
    validators: (v) => {
      if (!v) return undefined;
      else if (typeof v === "string") return undefined;
      const file = v[0];
      const is_safe_size = SHARED_VALIDATIONS.isFileSizeLessThan(file, 5, "MB");
      return !is_safe_size ? undefined : { [is_safe_size]: true };
    },
  });

  const Actions = Atom.Form.createFormActions({
    exhibition_title,
    exhibition_about,
    exhibition_interest,
    exhibition_experience,
    ExhibitionPdf,
  });
  return {
    Actions,
    exhibition_title,
    exhibition_about,
    exhibition_interest,
    exhibition_experience,
    ExhibitionPdf,
  };
}
